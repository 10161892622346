import { ColumnHeader } from "@/components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { Rule } from "./rule";

export const AttachedRules = () => {
  const rules = useSelector((state: RootState) => {
    return Object.values(state.rules).filter(
      (rule) => rule.twitterUserIds.indexOf(state.ui.twitterUserId) > -1
    );
  });
  if (rules.length === 0) {
    return null;
  }
  return (
    <div className="q-column">
      <ColumnHeader text="Attached Rules" button={"Add"} />
      <div className="q-overflow">
        <div className="q-scroll shadow-md">
          {rules.map(({ _id: ruleId, actionSets }, i) => {
            return <Rule key={i} ruleId={ruleId} actionSets={actionSets} />;
          })}
        </div>
      </div>
    </div>
  );
};
