import { RootState, slices } from "@/store";
import { IPopupProps } from "@/types/components";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "./button";
import { Column, ColumnHeader, Row, Scroll } from "./utilities";

export const Popup = () => {
  const popup = useSelector((state: RootState) => {
    return state.ui.popups[state.ui.popups.length - 1];
  });

  if (!popup) {
    return null;
  }

  return <PopupView {...popup} />;
};

export const PopupView = (props: IPopupProps) => {
  const { id, title, text = "", canClose = true, buttons = [] } = props;
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (id) {
      setVisible(true);
    }
  }, [id]);

  const close = useCallback(() => {
    setVisible(false);
    setTimeout(() => {
      dispatch(slices.uiSlice.actions.removePopup());
    }, 250);
  }, [dispatch]);

  if (!visible) {
    return null;
  }

  return (
    <div className="z-20 absolute bg-opacity-90 inset-0 p-4 bg-neutral-900 flex justify-center items-start">
      <div className="w-full sm:w-72">
        <Column>
          <ColumnHeader
            text={title}
            onPress={close}
            button={canClose ? "Close" : ""}
          />
          <Scroll outer>
            <Row>
              <p>{text}</p>
            </Row>
            {buttons.length ? (
              <Row>
                <div className="flex flex-col gap-2">
                  {buttons.map(({ text, action }, i) => {
                    return (
                      <Button
                        key={i}
                        onPress={() => {
                          action && dispatch(action);
                          close();
                        }}
                        text={text}
                      />
                    );
                  })}
                </div>
              </Row>
            ) : null}
          </Scroll>
        </Column>
      </div>
    </div>
  );
};
