import { NavigateFunction } from "react-router-dom";

let navigate: NavigateFunction;
export const setNavigation = (n: NavigateFunction) => {
  navigate = n;
};

export const getNavigation = () => {
  return navigate;
};
