import { createAsyncThunk } from "@reduxjs/toolkit";
import { slices } from "@/store";
import { navigate } from "./navigation";
import { doRequest } from "@/services";
import { ApiV1 } from "@/types";

export const login = createAsyncThunk(
  "ui/login",
  async (
    {
      feedbackId,
      username,
      password,
    }: { feedbackId: string; username: string; password: string },
    thunkApi
  ) => {
    const { dispatch } = thunkApi;

    //ToDo move into separate thunk
    dispatch(slices.feedback.actions.start(feedbackId));

    await doRequest<ApiV1.Auth.Login>("get", "/login");

    dispatch(
      slices.feedback.actions.end({
        id: feedbackId,
        state: "success",
        description: "Success! Redirecting...",
      })
    );

    dispatch(navigate("/"));

    return true;
  }
);

export const logout = createAsyncThunk(
  "ui/logout",
  async (feedbackId: string, thunkApi) => {
    const { dispatch } = thunkApi;

    //ToDo move into separate thunk
    dispatch(slices.feedback.actions.start(feedbackId));

    //ToDo call network
    await doRequest<ApiV1.Auth.Logout>("get", "/logout");

    //ToDo remove tokens

    dispatch(
      slices.feedback.actions.end({
        id: feedbackId,
        state: "success",
        description: "",
      })
    );

    dispatch(navigate("/start"));

    return true;
  }
);
