import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, slices } from "@/store";

export const useFeedback = () => {
  const feedbackId = useMemo(() => {
    return nanoid();
  }, []);
  useEffect(() => {
    slices.feedback.actions.start(feedbackId);
  }, [feedbackId]);
  const feedbackObj = useSelector(
    (state: RootState) => state.feedback[feedbackId]
  );
  const state = feedbackObj?.state || "idle";
  const description = feedbackObj?.description || "";

  const dispatch = useDispatch();

  useEffect(() => {
    if (state === "success") {
      setTimeout(() => {
        dispatch(slices.feedback.actions.clear(feedbackId));
      }, 5000);
    }
  }, [dispatch, feedbackId, state]);

  return {
    feedbackId,
    state,
    description,
    loading: state === "loading",
  };
};
