import { ModelsV1 } from "@/types";
import { configureStore } from "@reduxjs/toolkit";
import { createRecordSlice, uiSlice, feedback } from "./slices";

//todo make this generic
export const subscriptionSlice =
  createRecordSlice<ModelsV1.Subscription>("subscription");

export const twitterUserSlice =
  createRecordSlice<ModelsV1.TwitterUser>("twitterUser");

export const rules = createRecordSlice<ModelsV1.Rule>("rules");
export const tweets = createRecordSlice<ModelsV1.Tweet>("tweets");
export const triggers = createRecordSlice<ModelsV1.Trigger>("triggers");
export const users = createRecordSlice<ModelsV1.User>("users");

export const store = configureStore({
  reducer: {
    subscription: subscriptionSlice.reducer,
    twitterUser: twitterUserSlice.reducer,
    ui: uiSlice.reducer,
    feedback: feedback.reducer,
    rules: rules.reducer,
    tweets: tweets.reducer,
    triggers: triggers.reducer,
    users: users.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const slices = {
  subscriptionSlice,
  twitterUserSlice,
  uiSlice,
  feedback,
  rules,
  tweets,
  triggers,
  users,
};
