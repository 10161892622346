import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type FeedbackId = string;
interface FeedbackElement {
  id: FeedbackId;
  state: "idle" | "loading" | "success" | "error";
  description: string;
}

interface FeedbackState {
  [key: FeedbackId]: FeedbackElement;
}

const initialState: FeedbackState = {};

export const feedback = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    start: (state, action: PayloadAction<FeedbackId>) => {
      const feedbackId = action.payload;
      state[action.payload] = {
        id: feedbackId,
        state: "loading",
        description: "",
      };
    },
    end: (state, action: PayloadAction<FeedbackElement>) => {
      state[action.payload.id] = action.payload;
    },
    clear: (state, action: PayloadAction<FeedbackId>) => {
      delete state[action.payload];
    },
  },
});
