import React from "react";
import { Numbers } from "../utilities";
import { Button } from "./button";
import { AiOutlineTwitter } from "react-icons/ai";
import { IoTriangleSharp, IoCheckmarkSharp } from "react-icons/io5";
import { IoMdHourglass } from "react-icons/io";
import { RiLoader4Fill } from "react-icons/ri";
import { BiErrorCircle } from "react-icons/bi";

import { Link } from "./link";

interface IScrollProps {
  children: React.ReactNode;
  outer?: boolean;
}

export const Scroll = ({ children = null, outer = false }: IScrollProps) => {
  return (
    <div
      className={`divide-y divide-solid divide-neutral-700 pl-5 bg-neutral-800 ${
        outer ? "shadow-md" : ""
      }`}
    >
      {children}
    </div>
  );
};

interface IRowProps {
  children: React.ReactNode;
  pressable?: boolean;
  onPress?: () => void;
}

export const Row = ({
  children = null,
  pressable = false,
  onPress = () => null,
}: IRowProps) => {
  return (
    <div
      onClick={onPress}
      className={`relative flex flex-row items-center p-5 pl-0 ${
        pressable ? "cursor-pointer group" : ""
      }`}
    >
      {children}
    </div>
  );
};

interface ISelectorProps {
  selected?: boolean;
}
export const Selector = ({ selected = false }: ISelectorProps) => {
  return (
    <div
      className={`absolute top-0 bottom-0 -left-5 w-1 ${
        selected ? "bg-blue-500" : "group-hover:bg-blue-300"
      } transition-all`}
    />
  );
};

interface IColumnProps {
  children: React.ReactNode;
}

export const Column = ({ children = null }: IColumnProps) => {
  return (
    <div className="min-h-full h-full shrink-0 overflow-y-hidden">
      {children}
    </div>
  );
};

export const Overflow = ({ children = null }: IColumnProps) => {
  return (
    <div className="min-h-full h-full overflow-y-auto pb-14">{children}</div>
  );
};

interface IColumnHeaderProps {
  text: string;
  button: string;
  onPress?: () => void;
}

export const ColumnHeader = ({
  text = "Header",
  button = "Add",
  onPress,
}: IColumnHeaderProps) => {
  return (
    <div className="q-column-header">
      <p>{text}</p>
      {button ? <Button onPress={onPress} text={button} textSize="sm" /> : null}
    </div>
  );
};

interface ISubcriptionRowProps {
  image?: string;
  name: string;
  triggerCount: number;
  profit: number;
}

export const SubcriptionRow = ({
  image = "https://picsum.photos/200",
  name = "",
  triggerCount = 0,
  profit = 0,
}: ISubcriptionRowProps) => {
  return (
    <React.Fragment>
      <img
        className="w-12 h-12 rounded-full bg-neutral-500"
        src={image}
        alt="twitter-profile"
      />
      <div
        className="flex items-center mx-3 mr-4 gap-2 cursor-alias"
        onClick={() => null}
      >
        <p className="font-medium hover:underline peer">{name}</p>
        <AiOutlineTwitter
          color={"#1DA1F2"}
          size={20}
          className="invisible peer-hover:visible"
        />
      </div>
      <div className="flex-1" />
      <div className="flex flex-col items-end">
        <p className="font-medium text-green-500">
          {Numbers.formatCurrency(profit)}
        </p>
        <p className="italic">{triggerCount} triggers</p>
      </div>
    </React.Fragment>
  );
};

interface ITweetRowProps {
  source?: string;
  date?: string;
  text?: string;
  status?: "none" | "trigger" | "done";
  profit?: number;
}

export const TweetRow = ({
  source = "https://picsum.photos/200",
  date = "",
  text = "",
  status = "none",
  profit = 0,
}: ITweetRowProps = {}) => {
  return (
    <div className="flex flex-col justify-start items-start gap-3">
      <p className="text-neutral-400">{date}m</p>
      <p className="max-w-xs">{text}</p>
      <div className="flex flex-row w-full justify-between">
        <Link text="Source" />
        {Math.random() > 0.67 ? null : Math.random() > 0.5 ? (
          <p className="font-medium text-green-500">
            {Numbers.formatCurrency(profit)}
          </p>
        ) : (
          <p className="font-medium text-orange-500">Trigger in Progress</p>
        )}
      </div>
    </div>
  );
};

export const Arrow = () => {
  return <img src="arrow3.png" className="w-12 h-auto mt-4" alt="arrow" />;
};

interface IChipProps {
  text: string;
}

export const Chip = ({ text }: IChipProps) => {
  return (
    <div className="bg-neutral-900 px-2.5 pb-1 pt-0.5 rounded-full text-neutral-300">
      <p className="text-sm">{text}</p>
    </div>
  );
};

interface IAccordianToggleProps {
  open?: boolean;
}

export const AccordianToggle = ({
  open = false,
}: IAccordianToggleProps = {}) => {
  return (
    <IoTriangleSharp
      size={20}
      className={`text-blue-500 ${
        open ? "rotate-180 text-neutral-900" : ""
      } transition-all`}
    />
  );
};

interface IStepProps {
  index: number;
  status: "idle" | "inprogress" | "done" | "waiting" | "error";
  actionType: string;
  details: string[];
}

export const Step = ({ index, status, actionType, details }: IStepProps) => {
  let icon = null;
  let bgColor = "bg-white";
  let textColor = "text-white";
  let size = 20;
  switch (status) {
    case "inprogress": {
      bgColor = "bg-orange-500";
      textColor = "text-orange-500";
      icon = (
        <RiLoader4Fill size={size} className={`animate-spin ${textColor}`} />
      );
      break;
    }
    case "done": {
      bgColor = "bg-green-500";
      textColor = "text-green-500";
      icon = <IoCheckmarkSharp size={size} className={`${textColor}`} />;
      break;
    }
    case "waiting": {
      bgColor = "bg-neutral-500";
      textColor = "text-neutral-500";
      icon = (
        <IoMdHourglass size={size} className={`animate-pulse ${textColor}`} />
      );
      break;
    }
    case "error": {
      bgColor = "bg-red-500";
      textColor = "text-red-500";
      icon = <BiErrorCircle size={size} className={`${textColor}`} />;
      break;
    }
    default:
      break;
  }

  return (
    <div className="flex justify-start items-center gap-3">
      <p className={`w-12 italic ${textColor}`}>Step {index + 1}</p>
      {icon}
      <div className={`w-1 h-10 ${bgColor}`} />
      <p className={`font-semibold italic`}>{actionType.toUpperCase()}</p>
      <div className="flex gap-1">
        {details.map((text, i) => (
          <Chip key={i} text={text} />
        ))}
      </div>
    </div>
  );
};
