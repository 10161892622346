import { doRequest } from "@/services";
import { ApiV1 } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { slices } from "@/store";

export const loadDashboard = createAsyncThunk(
  "dashboard/load",
  async (feedbackId: string, thunkApi) => {
    const { dispatch } = thunkApi;

    //ToDo move into separate thunk
    dispatch(slices.feedback.actions.start(feedbackId));

    // you can wait for thunk actions to complete w/
    // const resultAction = await dispatch(updateUser(userData))
    // if (updateUser.fulfilled.match(resultAction)) {}

    const { data, error } = await doRequest<ApiV1.Dashboard.LoadResult>(
      "get",
      "/dashboard"
    );

    if (data) {
      const { subscriptions, rules, triggers, tweets, twitterUsers, users } =
        data;

      dispatch(slices.subscriptionSlice.actions.upsert(subscriptions));
      dispatch(slices.twitterUserSlice.actions.upsert(twitterUsers));
      dispatch(slices.rules.actions.upsert(rules));
      dispatch(slices.tweets.actions.upsert(tweets));
      dispatch(slices.triggers.actions.upsert(triggers));
      dispatch(slices.users.actions.upsert(users));

      //can def move feedback into a feedback thunk for networking
      dispatch(
        slices.feedback.actions.end({
          id: feedbackId,
          state: "success",
          description: "",
        })
      );
    } else if (error) {
      dispatch(
        slices.feedback.actions.end({
          id: feedbackId,
          state: "error",
          description: error.message,
        })
      );
      alert("Sorry, there was an error loading the dashboard.");
    }

    return true;
  }
);
