import { useCallback } from "react";

interface Props {
  text: string;
  textSize?: "sm" | "md" | "lg";
  source?: string;
}

export const Link = ({ text = "Link", textSize = "md", source }: Props) => {
  const notAvailable = useCallback(() => {
    alert("Sorry, this feature is not available for the demo. ");
  }, []);

  const open = useCallback(() => {
    window.open(source);
  }, [source]);

  return (
    <button
      onClick={source ? open : notAvailable}
      className={`
        text-${textSize} text-neutral-500 font-medium
        active:text-neutral-400 transition-all
        underline
      `}
    >
      {text}
    </button>
  );
};
