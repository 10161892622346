import React from "react";
import { ModelsV1 } from "@/types";
import { Link, Row, Scroll, Step } from "@/components";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface Props {
  ruleId: string;
  actionSets: ModelsV1.Rule["actionSets"];
  open?: boolean;
}

export const Rule = ({ ruleId, actionSets, open }: Props) => {
  const rule = useSelector((state: RootState) => {
    return state.rules[ruleId];
  });
  if (!rule) {
    return null;
  }
  const { title, matchCases } = rule;
  return (
    <Row>
      <div className="flex flex-col">
        <details open={open}>
          <summary className="cursor-pointer marker:text-blue-500">
            <p className="inline font-medium">Rule: {title}</p>
          </summary>
          <Scroll>
            <Row>
              <div className="flex gap-2 rounded-full justify-center items-center">
                {matchCases.map(({ type, value }, i) => {
                  return (
                    <React.Fragment key={i}>
                      <p>{type}</p>
                      <p className="bg-fuchsia-500 font-medium px-0.5">
                        {value}
                      </p>
                      {i < matchCases.length - 1 ? (
                        <p className="text-xl">;</p>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </div>
            </Row>
            {actionSets.map(({ title, actions }, i) => {
              return (
                <Row key={i}>
                  <details open={open}>
                    <summary className="cursor-pointer marker:text-blue-500">
                      <p className="inline font-medium">Action: {title}</p>
                    </summary>
                    <Scroll>
                      <div className="flex flex-col mt-5 items-start">
                        {actions.map(({ type, params, status }, i) => {
                          const details = Object.values(params).map((p) =>
                            p.toString()
                          );
                          return (
                            <Step
                              key={i}
                              index={i}
                              status={status}
                              actionType={type}
                              details={details}
                            />
                          );
                        })}
                      </div>
                    </Scroll>
                  </details>
                </Row>
              );
            })}
          </Scroll>
          <div className="pb-1">
            <Link text="Detach Rule Set" />
          </div>
        </details>
      </div>
    </Row>
  );
};
