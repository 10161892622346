import { Link, Row, Selector } from "@/components";
import { RootState, slices } from "@/store";
import { ModelsV1 } from "@/types";
import { Numbers } from "@/utilities";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export const Tweet = ({ _id, text, date, source }: ModelsV1.Tweet) => {
  const dispatch = useDispatch();

  const selectedTweetId = useSelector((state: RootState) => {
    return state.ui.tweetId;
  });

  const triggers = useSelector((state: RootState) => {
    return Object.values(state.triggers).filter(
      (trigger) => trigger.tweetId === _id
    );
  });

  const statuses = _.chain(triggers).map("status").toArray().countBy().value();

  let status: JSX.Element | null = null;
  if (statuses[ModelsV1.Enum.ActionStatus.Error]) {
    status = <p className="font-medium text-red-500">Error</p>;
  } else if (statuses[ModelsV1.Enum.ActionStatus.InProgress]) {
    status = <p className="font-medium text-orange-500">Trigger in Progress</p>;
  } else if (statuses[ModelsV1.Enum.ActionStatus.Done] === triggers.length) {
    const totalProfit = triggers.reduce((prev, curr) => prev + curr.profit, 0);
    status = (
      <p className="font-medium text-green-500">
        {Numbers.formatCurrency(totalProfit)}
      </p>
    );
  }

  const select = useCallback(() => {
    dispatch(slices.uiSlice.actions.setTweetId(_id));
  }, [_id, dispatch]);

  const ago = useMemo(() => {
    return moment(date).fromNow(true);
  }, [date]);

  return (
    <Row pressable onPress={select}>
      <Selector selected={selectedTweetId === _id} />
      <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-neutral-400">{ago}</p>
        <p className="max-w-xs">{text}</p>
        <div className="flex flex-row w-full justify-between">
          <Link text="Source" source={source} />
          {status}
        </div>
      </div>
    </Row>
  );
};
