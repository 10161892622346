import React, { useCallback } from "react";
import { Numbers } from "@/utilities";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { ModelsV1 } from "@/types";
import { Row, Selector } from "@/components";
import { slices } from "@/store";

export const Subscription = ({
  _id,
  twitterUserId,
  triggerCount = 0,
  profit = 0,
}: ModelsV1.Subscription) => {
  const dispatch = useDispatch();

  //ToDo move into selectors folder
  const twitterUser = useSelector((state: RootState) => {
    return state.twitterUser[twitterUserId];
  });

  const selectedTwitterUserId = useSelector((state: RootState) => {
    return state.ui.twitterUserId;
  });

  const select = useCallback(() => {
    dispatch(slices.uiSlice.actions.setTwitterUserId(twitterUserId));
  }, [dispatch, twitterUserId]);

  if (!twitterUser) {
    return null;
  }

  const { image, username } = twitterUser;

  return (
    <Row pressable onPress={select}>
      <Selector selected={selectedTwitterUserId === twitterUserId} />
      <React.Fragment>
        <img
          className="w-12 h-12 rounded-full bg-neutral-500"
          src={image}
          alt="twitter-profile"
        />
        <div className="flex items-center mx-3 mr-4 gap-2">
          <p className="font-medium">{username}</p>
        </div>
        <div className="flex-1" />
        <div className="flex flex-col items-end">
          <p className="font-medium text-green-500">
            {Numbers.formatCurrency(profit)}
          </p>
          <p className="italic">{triggerCount} triggers</p>
        </div>
      </React.Fragment>
    </Row>
  );
};
