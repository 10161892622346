import { config } from "@/consts";
const axios = require("axios").default;

const instance = axios.create({
  baseURL: config.apiUrl,
  timeout: 30000,
});

instance.defaults.headers.common["Authorization"] = "foobar";

interface GenericError {
  code: number;
  message: string;
}
interface NetworkResult<T> {
  data?: T | undefined;
  error?: GenericError | undefined;
}

export const doRequest = async <ExpectedResult>(
  type: "get" | "post",
  url: string
): Promise<NetworkResult<ExpectedResult>> => {
  try {
    const { data } = await instance[type](url);
    if (data.error) {
      return { error: data.error };
    }
    return {
      data,
    };
  } catch (error) {
    console.log(JSON.stringify(error));
    const genericError: GenericError = {
      code: 1,
      message: "todo",
    };
    return { error: genericError };
  }
};
