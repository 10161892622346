import { ColumnHeader } from "@/components";
import { usePrevious } from "@/hooks";
import { RootState, slices } from "@/store";
import { ModelsV1 } from "@/types";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Tweet } from "./tweet";

export const Tweets = () => {
  const dispatch = useDispatch();
  const selectedTwitterUserId = useSelector((state: RootState) => {
    return state.ui.twitterUserId;
  });
  const tweets = useSelector((state: RootState) => {
    return Object.values(state.tweets)
      .filter(({ twitterUserId }) => twitterUserId === selectedTwitterUserId)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  });

  const prevTweets = usePrevious(tweets);

  useEffect(() => {
    if (_.xor(prevTweets, tweets).length > 0) {
      dispatch(slices.uiSlice.actions.setTweetId(tweets[0]._id));
    }
  }, [dispatch, tweets, prevTweets]);

  if (!tweets.length) {
    return null;
  }

  return (
    <div className="q-column">
      <ColumnHeader text="Tweets" button={"Refresh"} />
      <div className="q-overflow">
        <div className="q-scroll shadow-md">
          {tweets.map((tweet, i) => {
            return <Tweet key={i} {...tweet} />;
          })}
        </div>
      </div>
    </div>
  );
};
