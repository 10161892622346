import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModelsV1 } from "@/types";

interface GenericState<T extends ModelsV1.DatabaseRecord> {
  [key: string]: T;
}

export const createRecordSlice = <T extends ModelsV1.DatabaseRecord>(
  name: string
) => {
  return createSlice({
    name,
    initialState: {} as GenericState<T>,
    reducers: {
      upsert: (state, action: PayloadAction<T[]>) => {
        for (const entity of action.payload) {
          //todo fix this
          //@ts-ignore
          state[entity._id] = entity;
        }
      },
    },
  });
};
