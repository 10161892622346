import { useFeedback } from "@/hooks";
import { AppDispatch } from "@/store";
import { loadDashboard } from "@/thunks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useDashboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const { feedbackId, state } = useFeedback();
  useEffect(() => {
    dispatch(loadDashboard(feedbackId));
  }, [dispatch, feedbackId]);
  return {
    feedbackId,
    ready: state === "idle" || state === "success",
  };
};
