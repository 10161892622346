import React from "react";
import {
  Arrow,
  Button,
  Chip,
  Column,
  ColumnHeader,
  Link,
  Overflow,
  Row,
  Scroll,
  Selector,
  Step,
  TweetRow,
} from "../components";
import { FaWallet } from "react-icons/fa";
import { faker } from "@faker-js/faker";
import { Subscriptions } from "./subscriptions/subscriptions";
import { useDashboard } from "@/containers";
import { RiLoader4Fill } from "react-icons/ri";
import { logout } from "@/thunks";
import { useConfirm, useFeedback } from "@/hooks";
import { AttachedRules } from "./rules/attachedRules";
import { Tweets } from "./tweets/tweets";
import { TriggeredRules } from "./rules/triggeredRules";

export const Dashboard = () => {
  const { ready } = useDashboard();
  const { feedbackId, state } = useFeedback();
  const { confirm } = useConfirm("Logout", logout(feedbackId));

  return (
    <React.Fragment>
      <header className="z-10 fixed h-12 w-full bg-neutral-900 px-4 shadow-md">
        <div className="relative flex flex-row justify-between items-center h-full 2xl:max-w-screen-2xl mx-auto">
          <Button
            loading={state === "loading"}
            text={"Logout"}
            onPress={confirm}
          />
          <h1
            className="text-xl font-medium"
            style={{
              fontFamily: "ocr-a-std",
            }}
          >
            <p className="sm:hidden">CMB</p>
            <p className="hidden sm:inline">CryptoMemeBot</p>
          </h1>

          <div className="bg-neutral-800 rounded-2xl px-4 py-1 pb-1.5 peer transition-all hover:ring-2 hover:ring-neutral-400 flex gap-2 justify-center items-center">
            {ready ? <FaWallet className="text-neutral-400" /> : null}
            <p
              className={`
              peer  
              text-sm
            text-neutral-400 font-medium
              cursor-default
              transition-all
            `}
            >
              {ready ? "$32,605" : "Loading..."}
            </p>
          </div>
          <div className="invisible absolute right-0 top-14 peer-hover:visible">
            <Column>
              <ColumnHeader text="Balances" button={""} />
              <Overflow>
                <Scroll outer>
                  <Row>
                    <Chip text="USD" />
                    <p className="ml-2">$32,102.22</p>
                  </Row>
                  <Row>
                    <Chip text="XDG" />
                    <p className="ml-2">11,110.10</p>
                  </Row>
                </Scroll>
              </Overflow>
            </Column>
          </div>
        </div>
      </header>

      {ready ? (
        <div className="min-h-full h-full flex flex-row justify-start 2xl:justify-center items-start overflow-x-auto pt-16 p-4 pb-0">
          <Subscriptions />
          <Arrow />
          <AttachedRules />
          <Arrow />
          <Tweets />
          <Arrow />
          <TriggeredRules />
        </div>
      ) : (
        <div className="min-h-full h-full flex flex-row justify-center items-start overflow-x-auto pt-16 p-4 pb-0">
          <div className="flex flex-row gap-1 justify-center items-center">
            <RiLoader4Fill
              size={20}
              className={`animate-spin text-neutral-500 mt-0.5`}
            />
            <p className="text-lg text-neutral-500">Loading Dashboard</p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
