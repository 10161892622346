import { useCallback } from "react";
import { RiLoader4Fill } from "react-icons/ri";

interface Props {
  text: string;
  active?: boolean;
  textSize?: "sm" | "md" | "lg";
  onPress?: () => void;
  loading?: boolean;
  type?: "button" | "submit";
}

export const Button = ({
  text = "Button",
  active = true,
  textSize = "sm",
  onPress,
  loading = false,
  type = "button",
}: Props) => {
  const color = active && !loading ? "blue-500" : "neutral-400";

  const notAvailable = useCallback(() => {
    alert("Sorry, this feature is not available in the demo.")
  }, []);

  return (
    <button
      type={type}
      disabled={loading}
      onClick={onPress || (type === "button" ? notAvailable : () => null)}
      className={`
        flex flex-row gap-1 justify-center items-center
      bg-neutral-800 
        rounded-2xl px-4 py-1 pb-1.5 
        text-${textSize} text-${color} font-medium
        active:translate-y-0.5 transition-all hover:ring-2 hover:ring-${color} 
        active:bg-blue-500 active:ring-0 active:text-white
      `}
    >
      {loading ? (
        <RiLoader4Fill size={20} className={`animate-spin text-neutral-400`} />
      ) : null}

      {text}
    </button>
  );
};
