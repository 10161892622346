import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, slices } from "@/store";

export const useConfirm = (description: string, action: any) => {
  const dispatch: AppDispatch = useDispatch();
  const confirm = useCallback(() => {
    const params = {
      title: description,
      text: `Are you sure you want to ${description}?`,
      buttons: [
        {
          text: "Yes",
          action,
        },
        {
          text: "No",
        },
      ],
    };
    dispatch(slices.uiSlice.actions.addPopup(params));
  }, [action, description, dispatch]);

  return {
    confirm,
  };
};
