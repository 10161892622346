import {
  TwitterUser,
  Tweet,
  User,
  Subscription,
  Rule,
  Trigger,
  Action,
  Enum,
} from "./types/models/v1";

import { Dashboard } from "./types/api/v1";

const axios = require("axios").default;

const twitterUser: TwitterUser = {
  _id: "tu:1",
  username: "Elon Musk",
  image: "https://image",
};

const tweet: Tweet = {
  _id: "t:1",
  twitterUserId: twitterUser._id,
  text: "Doge is great.",
  date: new Date().toString(),
  sentiment: Enum.SentimentValues.Positive,
  source: "https://twitter.com",
};

const user: User = {
  _id: "u:1",
  username: "Josh",
  email: "josh@cmb.com",
  password: "foobar",
};

const subscription: Subscription = {
  _id: "s:1",
  userId: user._id,
  profit: 0,
  triggerCount: 0,
  twitterUserId: twitterUser._id,
};

const actions: Action[] = [
  {
    type: Enum.ActionType.SMS,
    params: {
      recipient: "self",
      type: "info",
    },
    status: Enum.ActionStatus.Idle,
  },
  {
    type: Enum.ActionType.BUY,
    params: {
      token: Enum.CryptoTokens.Doge,
      amount: "100%",
    },
    status: Enum.ActionStatus.Idle,
  },

  {
    type: Enum.ActionType.WAIT,
    params: {
      seconds: 7 * 60 * 1000,
    },
    status: Enum.ActionStatus.Idle,
  },
  {
    type: Enum.ActionType.SELL,
    params: {
      token: Enum.CryptoTokens.Doge,
      amount: "100%",
    },
    status: Enum.ActionStatus.Idle,
  },
];

const rule: Rule = {
  _id: "r:1",
  userId: user._id,
  title: "Rule Set 1",
  actionSets: [
    {
      title: "Action Set 1",
      actions,
    },
  ],
  matchCases: [
    { type: Enum.MatchKey.Text, value: "Doge" },
    { type: Enum.MatchKey.Sentiment, value: Enum.SentimentValues.Positive },
  ],
  twitterUserIds: [twitterUser._id],
};

const trigger: Trigger = {
  _id: "r:1",
  userId: user._id,
  twitterUserId: twitterUser._id,
  tweetId: tweet._id,
  actionSets: rule.actionSets,
  ruleId: rule._id,
  profit: 0,
  status: Enum.ActionStatus.Idle,
};

const instance = axios.create({
  baseURL: "http://localhost:3000",
});

instance.defaults.headers.common["Authorization"] = "foobar";

export const loadDashboard: Dashboard.Load = async (userId) => {
  try {
    const { data } = await axios.get("/");
  } catch (error) {}

  return {
    subscriptions: [subscription],
    rules: [rule],
    triggers: [trigger],
    tweets: [tweet],
    twitterUsers: [twitterUser],
    user: [user],
  };
};

loadDashboard("1");
