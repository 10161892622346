import { ColumnHeader } from "@/components";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { Rule } from "./rule";

export const TriggeredRules = () => {
  const selectedTweetId = useSelector((state: RootState) => {
    return state.ui.tweetId;
  });
  const triggers = useSelector((state: RootState) => {
    return Object.values(state.triggers).filter(
      (trigger) => trigger.tweetId === selectedTweetId
    );
  });
  if (triggers.length === 0) {
    return null;
  }
  return (
    <div className="q-column">
      <ColumnHeader text="Triggered Rules" button={"Refresh"} />
      <div className="q-overflow">
        <div className="q-scroll shadow-md">
          {triggers.map(({ ruleId, actionSets }, i) => {
            return <Rule key={i} ruleId={ruleId} actionSets={actionSets} open/>;
          })}
        </div>
      </div>
    </div>
  );
};
