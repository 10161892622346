import { Routes, Route, useNavigate } from "react-router-dom";
import { Dashboard, Login, Splash } from "./views";
import "./typeExample";
import { Popup } from "@/components";
import { useEffect } from "react";
import { setNavigation } from "@/services";

function App() {
  const navigation = useNavigate();
  useEffect(() => {
    setNavigation(navigation);
  }, [navigation]);
  return (
    <div className="transition-all bg-gradient-to-t from-neutral-900 to-neutral-800 overflow-auto text-white h-full">
      {/* <div className="min-h-full h-full flex flex-1 flex-col">
        <div className="flex flex-1 bg-red-500"></div>
        <div className="flex flex-1 bg-green-500"></div>
      </div> */}

      <Popup />
      <Routes>
        <Route path="/start" element={<Splash />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
