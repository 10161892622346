export enum MatchKey {
  Text = "text",
  Sentiment = "sentiment",
}

export enum SentimentValues {
  Positive = "positive",
  Negative = "negative",
  Neutral = "neutral",
  None = "none",
}

export enum ActionType {
  SMS = "sms",
  BUY = "buy",
  WAIT = "wait",
  SELL = "sell",
}

export enum CryptoTokens {
  Doge = "XDG",
  Bitcoin = "BTC",
}

export enum ActionStatus {
  Idle = "idle",
  Waiting = "waiting",
  InProgress = "inprogress",
  Done = "done",
  Error = "error",
}
