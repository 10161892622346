import { ColumnHeader } from "@/components";
import { usePrevious } from "@/hooks";
import { RootState, slices } from "@/store";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Subscription } from "./subscription";

export const Subscriptions = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector((state: RootState) => {
    return Object.values(state.subscription).sort(
      (a, b) => b.profit - a.profit
    );
  });

  const prevSubscriptions = usePrevious(subscriptions);

  useEffect(() => {
    if (_.xor(prevSubscriptions, subscriptions).length > 0) {
      dispatch(
        slices.uiSlice.actions.setTwitterUserId(subscriptions[0].twitterUserId)
      );
    }
  }, [dispatch, prevSubscriptions, subscriptions]);

  return (
    <div className="q-column">
      <ColumnHeader text="Subscriptions" button={"Add"} />
      <div className="q-overflow">
        <div className="q-scroll shadow-md">
          {subscriptions.map((subscription, i) => {
            return <Subscription key={i} {...subscription} />;
          })}
        </div>
      </div>
    </div>
  );
};
