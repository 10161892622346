import { slices } from "@/store";
import React, { useCallback } from "react";
import { IoMailSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SPLASH from "../assets/splashClippedOpacity.png";
import { Button, Link } from "../components";

interface ISplashRowProps {
  title: string;
  subTitle: string;
  description: string;
  image: string;
  imageCaption?: string;
}

const splashRows = [
  {
    title: "Step 1",
    subTitle: "Connect to your exchange",
    description:
      "We are never able to access your wallet's funds.\nYou can revoke our trading access at any time.",
    image: "splashRow0.png",
    imageCaption: "Currently, only Kraken is supported.",
  },
  {
    title: "Step 2",
    subTitle: "Subscribe to your favorite influencers.",
    description:
      "Elon Musk and his crypto ramblings, anybody? Who knows what Trump's going to say next...",
    image: "splashRow1.png",
  },
  {
    title: "Step 3",
    subTitle: "Trigger trades based off of what they say.",
    description:
      "Influencers can change the price of a crypto by tweeting about it. Get the drop before the price jumps.",
    image: "splashRow2.png",
  },
  {
    title: "Step 4",
    subTitle: "Track tweets and profits in real-time.",
    description:
      "Who has time to stay up for Elon's late-night doge rants. Wouldn't it be great to wake up to executed trades that made a profit?",
    image: "splashRow3.png",
  },
];

export const Splash = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const goHome = useCallback(() => {
    navigate("/login", { replace: false });
  }, [navigate]);

  const waitlist = useCallback(() => {
    const params = {
      title: "Thanks for your interest!",
      text: "We'll email you when it's your turn to try CryptoMemeBot.",
      canClose: false,
      buttons: [{ text: "Ok" }],
    };
    dispatch(slices.uiSlice.actions.addPopup(params));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div
        className="w-full h-2/3 bg-cover bg-center"
        style={{ backgroundImage: `url(${SPLASH})` }}
      >
        <div className="h-full flex flex-col items-center justify-center">
          <h1
            className="text-4xl sm:text-5xl font-medium"
            style={{
              fontFamily: "ocr-a-std",
            }}
          >
            CryptoMemeBot
          </h1>
          <h2 className="text-2xl sm:text-3xl mt-4 px-4 text-center">
            Automate crypto trades using live tweet analysis.
          </h2>
          <div className="flex flex-col mt-6 sm:mt-8 gap-2 items-center px-4">
            <div className="flex p-2 flex-row justify-center items-center bg-neutral-900 rounded-full pl-4 mb-4">
              <input
                className="w-full sm:w-auto text-lg bg-transparent placeholder:italic placeholder:text-neutral-500 px-2 text-white focus:outline-none"
                placeholder="Email Address"
              />
              <div className="hidden sm:block">
                <Button
                  onPress={waitlist}
                  text="Join Waitlist"
                  active
                  textSize="lg"
                />
              </div>
              <div className="sm:hidden">
                <Button
                  onPress={waitlist}
                  text="Waitlist"
                  active
                  textSize="lg"
                />
              </div>
            </div>
            <Button
              onPress={goHome}
              text="View Demo"
              textSize="lg"
              active={false}
            />
          </div>
        </div>
        <div className="flex justify-center -mt-6">
          <p className=" bg-neutral-700 p-2 px-3 text-xl sm:text-2xl font-medium">
            How does it work?
          </p>
        </div>
      </div>
      {splashRows.map((row, i) => {
        return <SplashRow key={i} row={row} i={i} />;
      })}
      <div className="flex flex-col items-center justify-center p-4 divide-y divide-neutral-700">
        <div className="flex flex-col justify-center items-center gap-2 py-10">
          <h2 className="text-2xl sm:text-3xl mb-4 px-4 text-center">
            Interested? Stay in the know.
          </h2>
          <div className="flex p-2 flex-row justify-center items-center bg-neutral-900 rounded-full pl-4 mb-4">
            <input
              className="w-full sm:w-auto text-lg bg-transparent placeholder:italic placeholder:text-neutral-500 px-2 text-white focus:outline-none"
              placeholder="Email Address"
            />
            <div className="hidden sm:block">
              <Button
                onPress={waitlist}
                text="Join Waitlist"
                active
                textSize="lg"
              />
            </div>
            <div className="sm:hidden">
              <Button onPress={waitlist} text="Waitlist" active textSize="lg" />
            </div>
          </div>
          <Button
            onPress={goHome}
            text="View Demo"
            textSize="lg"
            active={false}
          />
        </div>
        <div className="flex flex-col justify-center items-center py-10">
          <div className="flex justify-center gap-4 mb-10">
            <Link text="Terms" textSize="lg" />
            <Link text="Privacy" textSize="lg" />
            <Link text="FAQ" textSize="lg" />
            <Link text="Contact" textSize="lg" />
          </div>
          {/* <div>
            <img className="w-60 sm:w-72" src="natLogo.png" alt="nat logo" />
          </div>
          <p className="mt-10 font-medium">
            © 2022 New Atlantis Technologies, Inc.
          </p> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export const SplashRow = ({ row, i }: { row: ISplashRowProps; i: number }) => {
  const { title, subTitle, description, image, imageCaption } = row;
  return (
    <div
      className={`flex flex-col sm:flex-row w-full px-8 py-20  justify-center items-center gap-8 ${
        i % 2 === 0 ? "" : "bg-neutral-900"
      }`}
    >
      <div>
        <h1 className="font-medium text-2xl sm:text-3xl">{title}</h1>
        <h2 className="text-2xl selection:sm:text-3xl">{subTitle}</h2>
        <p className="max-w-md text-lg border-l-4 border-blue-500 pl-3 mt-2">
          {description}
        </p>
      </div>
      <div className="flex flex-col items-center justify-center text-center">
        <img className="w-64 sm:w-72" src={image} alt="stepimage" />
        <p className="text-sm">{imageCaption}</p>
      </div>
    </div>
  );
};
