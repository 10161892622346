import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { IoMailSharp } from "react-icons/io5";
import { BsFillKeyFill } from "react-icons/bs";

import {
  Button,
  Column,
  ColumnHeader,
  Overflow,
  Row,
  Scroll,
} from "../components";
import { useFeedback } from "@/hooks";
import { useDispatch } from "react-redux";
import { login } from "@/thunks";
import { AppDispatch } from "@/store";

export const Login = () => {
  const { feedbackId, loading, description, state } = useFeedback();
  const dispatch: AppDispatch = useDispatch();

  type Inputs = {
    [key: string]: string;
  };

  const initialInput: Inputs = {
    username: "demo@cryptomemebot.com",
    password: "12345678",
  };

  const [inputs, setInputs] = useState(initialInput);

  const loginLocal = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { username, password } = inputs;
      dispatch(login({ feedbackId, username, password }));
    },
    [dispatch, feedbackId, inputs]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputs({
        ...inputs,
        [event.target.name]: event.target.value,
      });
    },
    [inputs]
  );

  return (
    <React.Fragment>
      <div className="min-h-full h-full flex flex-row justify-center items-start overflow-x-auto p-4 pb-0">
        <Column>
          <ColumnHeader text="Welcome Back!" button={""} />
          <Overflow>
            <form onSubmit={loginLocal}>
              <Scroll outer>
                <Row>
                  <div className="flex flex-col">
                    <div className="flex  w-72 flex-row-reverse">
                      <input
                        autoFocus={true}
                        required
                        className="bg-transparent placeholder:italic placeholder:text-slate-400 block w-full pl-3 text-white peer focus:outline-none"
                        placeholder="Email Address"
                        type="email"
                        name="username"
                        value={inputs["username"] || ""}
                        onChange={handleChange}
                      />
                      <IoMailSharp
                        size={20}
                        className="text-slate-400 peer-focus:text-blue-500 mt-0.5"
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="flex flex-col">
                    <div className="flex w-72 flex-row-reverse">
                      <input
                        required
                        className="bg-transparent placeholder:italic placeholder:text-slate-400 block w-full pl-3 text-white peer focus:outline-none"
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={inputs["password"] || ""}
                        minLength={8}
                        onChange={handleChange}
                      />
                      <BsFillKeyFill
                        size={20}
                        className="text-slate-400 peer-focus:text-blue-500 mt-0.5"
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="flex flex-col">
                    <Button
                      type="submit"
                      loading={loading}
                      text="Login"
                      // onPress={loginLocal}
                    />
                    {/* ToDo this should def be its own kind of component*/}
                    {description ? (
                      <p
                        className={`mt-2 ${
                          state === "success"
                            ? "text-green-500"
                            : "text-red-500"
                        }  text-sm`}
                      >
                        {description}
                      </p>
                    ) : null}
                  </div>
                </Row>
              </Scroll>
            </form>
          </Overflow>
        </Column>
      </div>
    </React.Fragment>
  );
};
