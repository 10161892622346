import { Components, ModelsV1 } from "@/types";
import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

interface UiState {
  twitterUserId: ModelsV1.TwitterUserId;
  tweetId: ModelsV1.TweetId;
  popups: Components.IPopupProps[];
}

const initialState: UiState = {
  twitterUserId: "",
  tweetId: "",
  popups: [],
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTwitterUserId: (
      state,
      action: PayloadAction<ModelsV1.TwitterUserId>
    ) => {
      state.twitterUserId = action.payload;
    },
    setTweetId: (state, action: PayloadAction<ModelsV1.TweetId>) => {
      state.tweetId = action.payload;
    },
    addPopup: (state, action: PayloadAction<Components.IPopupProps>) => {
      action.payload.id = nanoid();
      state.popups.unshift(action.payload);
    },
    removePopup: (state) => {
      state.popups.pop();
    },
  },
});
